@import "styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 10px;
  font-family: "Manrope", sans-serif;
  scroll-behavior: smooth;
}

.text-align-center {
  text-align: center;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

section {
  padding: 68px 0;
}

.container {
  max-width: 1232px;
  margin: 0px auto;
  padding: 0px 16px;
}

.commonBtn {
  font-family: "Manrope";
  background-color: $color-green-0;
  color: $color-white-0;
  padding: 12px 28px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: $color-green-2;
    color: $color-white-0;
    transition: all 0.5s ease;

    img,
    svg {
      animation-name: arrowMove;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }

  &:not(:hover) {
    img,
    svg {
      animation-name: arrowMoveReverse;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }
}

.commonSubTitle {
  color: $color-green-0;
  @include font-bold($fs: 1.6rem);
  line-height: 150%;
  letter-spacing: -0.176px;
  text-align: center;
  white-space: nowrap;
  font-size: 36px !important;
}

.commonHeading {
  color: $color-black-2;
  @include font-med($fs: 4.8rem);
  line-height: 135%;
  letter-spacing: -0.528px;

  span {
    @include font-bold($fs: 4.8rem);
  }
}

.commonParaGraph {
  color: $color-gray-9;
  @include font-reg(1.8rem);
  letter-spacing: -0.198px;
  line-height: 150%;
}

// media queries
@media (min-width: 769px) and (max-width: 992px) {
  .container {
    padding: 0 24px;
  }

  .commonHeading {
    font-size: 3.2rem;

    span {
      font-size: 3.2rem;
    }
  }
}

@media (max-width: 768px) {
  section {
    padding: 48px 0;
  }

  .commonHeading {
    font-size: 28px;
    text-align: center;

    span {
      font-size: 28px;
    }
  }

  .commonParaGraph {
    font-size: 16px;
  }
}

// keyframes
@keyframes arrowMove {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(10px);
  }
}

@keyframes arrowMoveReverse {
  from {
    transform: translateX(10px);
  }

  to {
    transform: translateX(0px);
  }
}

.pt-68 {
  padding-top: 68px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

section {
  padding: 68px 0;
}

.container {
  max-width: 1232px;
  margin: 0px auto;
  padding: 0px 16px;
}

.commonBtn {
  background-color: $color-green-0;
  color: $color-white-0;
  padding: 10px 25px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all 0.2s ease;
}
.serviceBannerBtn {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.209px;
  font-family: "Manrope", sans-serif;
}

.commonBtn:hover {
  background-color: #089c78;
  color: $color-white-0;
  transition: all 0.5s ease;
}

.commonBtn:hover img,
.commonBtn:hover svg {
  animation-name: arrowMove;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.commonBtn:not(:hover) img,
.commonBtn:not(:hover) svg {
  animation-name: arrowMoveReverse;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.commonSubTitle {
  color: $color-green-0;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.176px;
  text-align: center;
  white-space: nowrap;
}

.commonHeading {
  color: $color-black-2;
  font-size: 4.8rem;
  font-weight: medium;
  line-height: 135%;
  letter-spacing: -0.528px;
}

.commonHeading span {
  font-weight: bold;
}

.commonParaGraph {
  color: $color-gray-2;
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: -0.198px;
  line-height: 150%;
}

@media (min-width: 769px) and (max-width: 992px) {
  .container {
    padding: 0 24px;
  }

  .commonHeading {
    font-size: 3.2rem;
  }

  .commonHeading span {
    font-size: 3.2rem;
  }
}

@media (max-width: 768px) {
  section {
    padding: 48px 0;
  }

  .commonHeading {
    font-size: 28px;
    text-align: center;
  }

  .commonHeading span {
    font-size: 28px;
  }

  .commonParaGraph {
    font-size: 16px;
  }
}

@keyframes arrowMove {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(10px);
  }
}

@keyframes arrowMoveReverse {
  from {
    transform: translateX(10px);
  }

  to {
    transform: translateX(0px);
  }
}

.subHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 56px;
}

.commonSubTitle {
  color: $color-green-0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.176px;
  text-align: center;
  white-space: nowrap;
}
.commonSubTitleDesc {
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.528px;
  color: $color-black-2;
  padding-top: 8px;
}
.commonSubTitlePara {
  color: $color-gray-1;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: -0.176px;
  font-family: "Inter", sans-serif;
}
.commonSubTitleSmallPara {
  color: $color-gray-1;
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.176px;
  font-family: "Inter", sans-serif;
}
.commonSubTitleDesc span {
  font-weight: bold;
}

.serviceBanner {
  display: flex;
  justify-content: center;
  height: 90vh;
}

.serviceBannerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.serviceBannerCommonHeading {
  font-family: "Inter", sans-serif;
}

.serviceBannerHeading p {
  color: $color-gray-2;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: -0.176px;
  padding: 20px 0 40px;
  font-family: "Inter", sans-serif;
}

.serviceBannerImage img {
  max-width: 614px;
}

@media (max-width: 768px) {
  .serviceBanner {
    padding: 48px 0;
  }

  .serviceBannerContainer {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .serviceBannerHeading h2 {
    text-align: inherit;
  }

  .serviceBannerHeading p {
    font-size: 16px;
  }

  .serviceBannerImage img {
    max-width: 100%;
  }
}

.card {
  background-color: $color-white-0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.resultHeading {
  color: $color-gray-1;
  font-size: $font-size-h2;
  line-height: 150%;
  letter-spacing: -0.176px;
  font-weight: 700;
}
.resultParaHeadingNormal {
  font-size: $font-size-p2;
  line-height: 150%;
  letter-spacing: -0.176px;
  font-weight: 500;
}
.resultParaHeading {
  font-size: $font-size-p2;
  line-height: 150%;
  // letter-spacing: -0.176px;
  font-weight: 700;
}

.resultPara {
  font-size: $font-size-p3;
  line-height: 150%;
  // letter-spacing: -0.176px;
}

.ql-snow {
  font-family: "Manrope" !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: $color-green-2 !important;
  border-color: $color-green-2 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: $color-green-0 !important;
  border-color: $color-green-0 !important;
}

.ant-steps-icon {
  color: $color-white-0 !important;
}

.ant-steps-item-title::after {
  background-color: $color-green-0;
}

.ant-steps-item-title:hover {
  color: $color-green-0 !important;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $color-green-0 !important;
}

.ant-steps-item-container:hover {
  .ant-steps-item-icon {
    border-color: $color-green-0 !important;
  }
  .ant-steps-item-title {
    color: $color-green-0 !important;
  }
}

.ant-tabs-nav {
  overflow: hidden !important;
}

.ant-select-selector {
  background-color: $color-white-0 !important;
  height: 40px !important;
}
.ant-select-dropdown {
  background-color: $color-white-0;
  border-radius: 8px;
}
.ant-picker-outlined {
  background-color: $color-white-0 !important;
  height: 40px !important;
}
.ant-picker-dropdown .ant-picker-panels {
  background-color: $color-white-0 !important;
}

.slider_container {
  .ant-slider-mark-text-active {
    margin-top: 7px;
    font-weight: 600;
    color: #758b89;
  }
  .ant-slider-dot-active {
    width: 15px;
    height: 15px;
    margin-top: -3px;
  }
  .ant-slider-handle::after {
    // margin-left: -3px;
    display: none;
  }
  .ant-slider-mark-text::after {
    color: black;
  }
}

.outer-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.575);
  z-index: 99999;
}

.loaderWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white-0;
  z-index: 10;
}

.inner-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.unordered_list {
  @include d-flex($jc: space-between);
  align-items: flex-start;
  flex-direction: column;
  gap: 18px;
  padding: 20px 0 20px;
  margin-left: 32px;
  li {
    position: relative;
    &::before {
      content: url("https://dj4gk1hnrapgw.cloudfront.net/tickCircle.svg");
      position: absolute;
      left: -36px;
    }
    b {
      color: $color-black-2;
      @include font-bold($fs: 1.8rem);
      line-height: 150%;
      letter-spacing: -0.176px;
    }

    span {
      color: $color-gray-2;
      @include font-reg($fs: 1.8rem);
      line-height: 150%;
      letter-spacing: -0.198px;

      b {
        @include font-bold($fs: 1.8rem);
        color: $color-gray-2;
      }
    }
  }
}
