@import "styles/variables.scss";

.header {
  padding: 0px 10px;
  border-bottom: 1px solid $color-gray-0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $color-white-0;
  z-index: 99;

  &Container {
    @include d-flex($jc: space-between);
    padding: 5px 0;
  }

  &Logo {
    max-width: 190px;
  }

  &List {
    @include d-flex($jc: space-between);
    gap: 40px;

    li {
      cursor: pointer;

      .menu_content {
        color: $color-black-2;
        @include font-med($fs: 1.6rem);
        line-height: 150%;
        letter-spacing: -0.176px;
      }

      &:hover {
        .menu_content {
          color: $color-green-2;
        }

        .arrowDown {
          svg {
            path {
              fill: $color-green-2;
            }
          }
        }
      }
    }
  }

  &Product {
    @include d-flex($jc: center);
    gap: 3px;

    &Popup {
      padding-top: 27px;

      &TagContainer {
        margin: 20px 5px;
      }

      &Container {
        display: grid;
        background-color: $color-white-2;
        border: 1px solid $color-white-2;
        grid-template-columns: repeat(3, 1fr);
        -webkit-box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 20px 36px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
      }

      h2 {
        margin: 20px 0 5px 20px;
        color: $color-black-2;
      }

      ul {
        @include d-flex($jc: center);
        flex-direction: column;
      }

      &List {
        @include d-flex($jc: flex-start);
        gap: 17px;
        width: 310px;
        padding: 12px 0 12px 16px;

        &:hover {
          gap: 10px;
          transition-duration: 0.5s;
        }

        .popupList {
          span {
            color: $color-black-2;
            @include font-semi-bold($fs: 1.6rem);
          }

          p {
            color: $color-gray-2;
            @include font-reg($fs: 1.4rem);
          }
        }
      }
    }
  }

  .loginSignUp {
    @include d-flex($jc: space-between);
    gap: 16px;

    button {
      border-radius: 4px;
      @include font-bold($fs: 1.6rem);
      line-height: 150%;
      letter-spacing: -0.176px;
      padding: 8px 28px;
      font-weight: 400;
      font-family: inherit;
      transition: all 0.2s ease;
    }

    .headerLogIn {
      background-color: $color-green-1;
      border: none;
      color: $color-green-2;
      cursor: pointer;

      &:hover {
        background-color: $color-green-3;
      }
    }
  }

  &Hamburger {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: $color-white-0;

    &Hamburger {
      display: block;
    }

    .loginSignUp {
      a {
        button {
          font-size: 1.2rem;
          padding: 4px 16px;
        }
      }
    }

    &List {
      z-index: 99999;
      overflow-y: auto;
      position: absolute;
      flex-direction: column;
      top: 40px;
      justify-content: flex-start;
      right: 0;
      background-color: $color-white-0;
      height: calc(100vh);
      width: 100%;
      padding: 24px 16px 0;
      padding-bottom: 100px;
      align-items: flex-start;
      gap: 12px;

      .arrow {
        display: none;
      }
    }

    &Product {
      &Popup {
        margin-top: 10px;
        position: static;
        padding: 0;
        width: 100%;
        &TagContainer {
          margin: 0px 5px;
        }
        &Container {
          grid-template-columns: 1fr;
          border-radius: 0;
          gap: 20px;

          background: none;
          box-shadow: none;
          border: none;
        }

        h2 {
          margin: 0px 0 0px 0px;
          font-size: 14px;
        }

        ul {
          li {
            padding-left: 0;
            max-height: 90px;

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .arrowDown {
      display: none;
    }
  }
}
