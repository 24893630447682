// Colors
$color-white-0: #ffffff;
$color-white-1: #fbfbfd;
$color-white-2: #fafafa;

$color-gray-0: #e5e5e5;
$color-gray-1: #525252;
$color-gray-2: #70807c;
$color-gray-3: #6d7a77;
$color-gray-4: #3d484d;
$color-gray-5: #ffffff40;
$color-gray-6: #677779;
$color-gray-7: #c4ccca;
$color-gray-8: #2b3235;
$color-gray-9: #737373;
$color-gray-10: #292929;

$color-green-0: #02b686;
$color-green-1: #f1f9f2;
$color-green-2: #089c78;
$color-green-3: #ddf5e0;

$color-black-0: #000000;
$color-black-1: #424242;
$color-black-2: #1a2f51;
$color-black-3: #0c1a20;
$color-black-4: #bdcbca;

$color-blue-0: #183b56;
$color-blue-1: #5a7184;
$color-blue-2: #0c5aec;

// Font mixins
@mixin font-reg($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 400;
}
@mixin font-med($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 500;
}
@mixin font-semi-bold($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 600;
}
@mixin font-bold($fs: 1.6rem) {
  font-size: $fs;
  font-weight: 700;
}
// Flex mixin
@mixin d-flex($jc: flex-start) {
  display: flex;
  align-items: center;
  justify-content: $jc;
}

//Box shadows
$box-shadow-1: 0px 4px 6px rgba(0, 0, 0, 0.125);

//font sizes
$font-size-h1: clamp(2.5rem, 2.5vw, 4rem);
$font-size-h2: clamp(1.5rem, 1.75vw, 2.5rem);
$font-size-p1: clamp(1.5rem, 2.5vw, 2rem);
$font-size-p2: clamp(1.25rem, 1.5vw, 1.8rem);
$font-size-p3: clamp(1.25rem, 1.25vw, 1.5rem);
