@import "styles/variables.scss";

.footer {
  padding: 150px 0 30px;
  background-image: url("https://dj4gk1hnrapgw.cloudfront.net/footer_Bg.png");
  background-size: cover;
  background-repeat: no-repeat;

  &Content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &Main {
    @include d-flex($jc: flex-start);
    gap: 18px;

    img {
      width: 550px;
    }
  }

  &Heading {
    h2 {
      color: $color-white-1;
      @include font-med($fs: 4.8rem);
      line-height: 150%;
      letter-spacing: -0.528px;
      text-align: center;

      span {
        font-weight: 700;
        color: $color-green-0;
        text-align: center;
      }
    }

    p {
      @include font-reg($fs: 1.8rem !important);
      color: $color-white-2;
      line-height: 150%;
      letter-spacing: -0.198px;
      opacity: 0.55;
      padding: 20px 0 40px;
      text-align: center;
      max-width: 489px;
      margin: 0 auto;
    }

    button {
      margin: 0 auto;
    }
  }

  &Mail {
    @include d-flex($jc: center);
    gap: 10px;
    background-color: $color-gray-4;
    width: fit-content;
    margin: 0 auto;
    padding: 10px 16px;
    border-radius: 40px;
    color: $color-white-2;
    @include font-semi-bold($fs: 1.6rem);
    line-height: 150%;
    letter-spacing: -0.176px;
    cursor: pointer;

    a {
      color: $color-white-2;
      @include font-semi-bold(1.6rem);
      line-height: 150%;
      letter-spacing: -0.176px;
    }

    &:hover {
      background-color: $color-gray-8;
    }
  }

  &Links {
    @include d-flex($jc: space-between);
    border-top: 1px solid $color-gray-5;
    margin: 32px 0 0 10px;
    padding-top: 18px;

    .socialLinks {
      display: flex;
      gap: 18px;

      svg {
        transition: all 0.2s ease;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
          transition: all 0.2s ease;
        }
      }
    }

    .footerLinksPage {
      display: flex;
      flex-direction: row;

      .footerLink {
        color: $color-white-2;
      }

      div:nth-child(1) {
        margin-left: 10px;
      }

      div:nth-child(2) {
        margin: 0px 15px;
      }
    }
  }

  p {
    color: $color-white-2;
    @include font-med($fs: 1.6rem);
    line-height: 150%;
    letter-spacing: -0.176px;
  }
}

// media queries
@media (min-width: 769px) and (max-width: 992px) {
  .footer {
    &Heading {
      h2 {
        font-size: 3.2rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 100px 0 16px;

    &Main {
      gap: 0;
      @include d-flex(center);

      img {
        display: none;
      }
    }

    &Heading {
      text-align: center;

      h2 {
        font-size: 2.8rem;
      }

      p {
        @include font-reg(1.6rem !important);
      }
    }

    &Links {
      padding-top: 16px;
    }
  }
}

@media (max-width: 440px) {
  .footer {
    .footerLinksPage {
      flex-direction: column;
      text-align: center;

      div:nth-child(1) {
        margin-left: 0px;
      }

      div:nth-child(2) {
        margin: 0px 0px;
      }
    }
  }
}

.navigationContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  .menuGrid {
    display: grid;
    width: fit-content;
    margin: auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @media (min-width: 600px) {
      width: fit-content;
      gap: 4rem 2rem;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .menuSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    .sectionTitle {
      font-size: 2.4rem;
      font-weight: 500;
      color: $color-green-0;
      margin-bottom: 1.5rem;
      position: relative;
    }

    .linkList {
      list-style: none;
      padding: 0;
      margin: 0;

      .linkItem {
        margin-bottom: 0.75rem;

        &:last-child {
          margin-bottom: 0;
        }

        .link {
          color: $color-white-2;
          text-decoration: none;
          transition: all 0.2s ease;
          font-size: 1.6rem;
          display: inline-block;
          position: relative;
          padding: 2px 0;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: $color-green-0;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.3s ease;
          }

          &:hover {
            color: $color-green-0;

            &::after {
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }
        }
      }
    }
  }
}
